import React, {
  useState,
  useEffect,
  createRef,
  forwardRef,
  useImperativeHandle,
} from "react"
import PropTypes from "prop-types"
import { Box, IconButton } from "theme-ui"
import { FaSlidersH, FaTimes } from "react-icons/fa"
// import useScrollDisabler from '@components/useScrollDisabler'
import Loadable from "@loadable/component"
import useClickOutside from "@helpers/useClickOutside"
import "./styles.css"

const DrawerMenu = Loadable(() => import("rc-drawer"))

const styles = {
  handler: {
    display: ["", "", ""], //to avoid ssr rehydration issue
    transition: `left 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)`,
    left: -4,
    bg: "omegaLighter",
    mr: 2,
  },
  handlerOpen: {
    position: `fixed`,
    zIndex: 99999,
    left: [4, 4, `95%`],
    top: 4,
  },
  filter: {
    position: "absolute",
    bottom: "34px",
    right: "36.6%",
    "&:hover": {
      bg: "transparent",
    },
  },
  content: {
    bg: `contentBg`,
    overflowY: "scroll",
    height: `full`,
    fontSize: 3,
    p: ["2rem", "3rem"],
  },
}

const DrawerFilter = forwardRef(
  ({ container, width, locationState, ...props }, ref) => {
    useImperativeHandle(ref, () => ({
      showAlert() {
        handleSwitch()
      },
    }))

    const [open, setOpen] = useState(false)
    const rootRef = createRef()

    useClickOutside(rootRef, () => setOpen(false))

    // eslint-disable-line react-hooks/exhaustive-deps

    const handleSwitch = () => {
      setOpen(true)
    }

    const handleSwitchClose = () => {
      setOpen(false)
    }

    const handlerStyle = open
      ? {
          ...styles.handler,
          ...styles.handlerOpen,
        }
      : styles.handler

    const handler = (
      <Box>
        {open ? (
          <IconButton onClick={handleSwitchClose} sx={handlerStyle} {...props}>
            <Box>
              <FaTimes />
            </Box>
          </IconButton>
        ) : (
          ""
        )}
      </Box>
    )

    useEffect(() => {
      open && locationState && handleSwitch()
    }, [locationState]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {/* {open && <ScrollDisabler />} */}
        {handler}
        <DrawerMenu
          width={width}
          open={open}
          getContainer={container}
          onHandleClick={handleSwitch}
          placement="right"
        >
          <Box ref={rootRef} className="content" sx={styles.content}>
            {props.children}
          </Box>
        </DrawerMenu>
      </>
    )
  }
)

export default DrawerFilter

DrawerFilter.defaultProps = {
  container: null,
}

DrawerFilter.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  container: PropTypes.instanceOf(PropTypes.element),
}
