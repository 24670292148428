import React from "react"
import Search from "@components/Search/Search"
import Layout from "@layout/Layout"

const searchIndices = [{ name: `VintageBikeStore` }]

// kell egy img varians = keresd meg az url-t a sku szerint

export default function shop() {
  return (
    <Layout>
      <Search indices={searchIndices} />
    </Layout>
  )
}
