import React, { useRef } from "react"
import {
  InstantSearch,
  CurrentRefinements,
  Breadcrumb,
  connectInfiniteHits,
  Configure,
  HierarchicalMenu,
  MenuSelect,
  Panel,
  RefinementList,
  SearchBox,
} from "react-instantsearch-dom"
import {
  Box,
  Container,
  Card,
  Flex,
  IconButton,
  Text,
  Button,
  Link,
  css,
  Heading,
} from "theme-ui"
import algoliasearch from "algoliasearch/lite"
import { Link as GLink } from "gatsby"
// import { GatsbyImage as Img } from 'gatsby-plugin-image'
import Sticky from "react-sticky-el"
// import Reveal from '@components/Reveal'
import Drawer from "@components/Drawer"
// import getImage from '@components/utils/getImage'
import Reveal from "@components/Reveal"
import { StaticImage } from "gatsby-plugin-image"
import "./Theme.css"
import "./App.css"
import "./App.mobile.css"
import DrawerFilter from "@components/Drawer/DrawerFilter"
import { FaSlidersH, FaSearch } from "react-icons/fa"
import TulajData from "./tulajdonsagok.json"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const Hits = ({ hits, hasMore, refineNext }) => {
  return (
    <div>
      <ul class="ais-Hits-list">
        {hits.map((hit, index) => {
          return hit.fot_11 && (
            <li class="ais-Hits-item" key={index}>
              <Box
                sx={{
                  size: "full",

                  display: "inline-block",
                }}
              >
                <Box
                  sx={{
                    m: "2",
                    position: "relative",
                    height: "17rem",
                  }}
                >
                  <Link as={GLink} key={hit.id} to={`/${hit.sLUG}`}>
                    {hit.fot_11 && (
                      <Card
                        variant="cards.interactive"
                        style={{
                          backgroundImage: "url(" + hit.fot_11 + ")",
                          backgroundSize: "100%",
                          backgroundPosition: "center",
                          width: "100%",
                          height: "11rem",
                        }}
                      />
                    )}
                    {!hit.fot_11 && (
                      <Card
                        variant="cards.interactive"
                        style={{
                          backgroundImage: "url(" + hit.fot_12 + ")",
                          backgroundSize: "100%",
                          backgroundPosition: "center",
                          width: "100%",
                          height: "11rem",
                        }}
                      />
                    )}
                    <Flex sx={{ p: 3 }}>
                      <Box sx={{ flexBasis: hit.webshop__r && "2/3" }}>
                        <Link
                          variant="mute"
                          sx={{ fontSize: "0.875rem" }}
                          as={GLink}
                          to={`/${hit.sLUG}`}
                        >
                          {hit.tERM_KN_V.substring(0, 38) +
                            (hit.tERM_KN_V.length > 38 ? "..." : "")}
                        </Link>
                      </Box>
                      {hit.sT_TUSZ === "ELADVA\n WEBSHOPBA FELTÖLTVE" ? (
                        <Box
                          sx={{ py: 3, flexBasis: "1/3", textAlign: "right" }}
                        >
                          ELADVA
                        </Box>
                      ) : (
                        <Box
                          sx={{ py: 3, flexBasis: "1/3", textAlign: "right" }}
                        >
                          {hit.webshop__r ? (
                            <Link
                              variant="mute"
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              as={GLink}
                              to={`/${hit.sLUG}`}
                            >
                              {hit.webshop__r} FT
                            </Link>
                          ) : (
                            ""
                          )}
                        </Box>
                      )}
                    </Flex>
                  </Link>
                </Box>
              </Box>
            </li>
          )
        })}
      </ul>
      {hasMore && (
        <Box sx={{ width: "100%", textAlign: "center", p: 4 }}>
          <Button disabled={!hasMore} onClick={refineNext}>
            Show more results
          </Button>
        </Box>
      )}
    </div>
  )
}

const CustomHits = connectInfiniteHits(Hits)

// const SearchBox = ({ currentRefinement, refine }) => (
//   <div class="ais-SearchBox">
//     <form
//       class="ais-SearchBox-form"
//       noValidate
//       role="search"
//       onSubmit={e => e.preventDefault()}
//     >
//       <input
//         type="search"
//         class="ais-SearchBox-input"
//         value={currentRefinement}
//         onChange={event => refine(event.currentTarget.value)}
//         placeholder="Search here..."
//       />
//     </form>
//   </div>
// )

// const CustomSearchBox = connectSearchBox(SearchBox)

const Search = () => {
  const [open, setOpen] = React.useState(false)

  const handleToggle = () => {
    setOpen(true)
  }

  const childRef = useRef()
  const toggle = useRef()

  const isBrowser = typeof window !== "undefined"
  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0)

  React.useEffect(() => {
    if (!isBrowser) return false
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return (
    <InstantSearch searchClient={searchClient} indexName="VintageBikeStore">
      <Sticky
        enabled="true"
        stickyClassName="nav-sticky"
        css={css(styles.wrapper)}
      >
        <Container variant="full" className="nav-container">
          <Container px="4">
            <Flex sx={styles.header}>
              <Box sx={styles.logoContainer}>
                <GLink to="/">
                  <StaticImage
                    placeholder="none"
                    src="../../../static/logo.png"
                  />
                </GLink>
              </Box>
              <Flex
                sx={{
                  display: ["none", "block"],
                  width: "full",
                  maxWidth: "405px",
                  mr: [0, 4],
                  position: ["unset", "relative"],
                }}
              >
                <Box>
                  <SearchBox />
                </Box>
              </Flex>
              <>
                <Box sx={styles.desktopMenu}>
                  <Reveal effect="fadeInDown">
                    <Flex
                      sx={{
                        alignItems: `center`,
                        justifyContent: `flex-end`,
                      }}
                      m="-3"
                    >
                      <Link
                        variant="mute"
                        sx={{ fontWeight: "600" }}
                        as={GLink}
                        to="/"
                      >
                        <Box m="3">HOME</Box>
                      </Link>
                      <Link
                        variant="mute"
                        sx={{ fontWeight: "600" }}
                        as={GLink}
                        to="/shop"
                      >
                        <Box m="3">PRODUCTS</Box>
                      </Link>
                      <a
                        variant="mute"
                        style={{
                          fontWeight: "600",
                          textDecoration: "unset",
                          color: "white",
                        }}
                        href="mailto: vintagebikestore@gmail.com"
                      >
                        <Box style={{
                              background: "#0062b1",
                              borderRadius: "25px",
                              padding: ".25rem 1rem",
                        }} m="3">
                          vintagebikestore@gmail.com
                        </Box>
                      </a>
                    </Flex>
                  </Reveal>
                </Box>
                <Box sx={styles.mobileMenu}>
                  <Drawer buttonStyle={{ svg: { size: 32 } }}>
                    <Box sx={{ fontSize: 3 }} m="3">
                      <Reveal effect="fadeInDown">
                        <Flex
                          sx={{
                            alignItems: `center`,
                            flexDirection: "column",
                            justifyContent: `flex-end`,
                          }}
                          m="-3"
                        >
                          <Link
                            variant="mute"
                            sx={{ fontWeight: "600" }}
                            as={GLink}
                            to="/"
                          >
                            <Box m="3">HOME</Box>
                          </Link>
                          <Link
                            variant="mute"
                            sx={{ fontWeight: "600" }}
                            as={GLink}
                            to="/shop"
                          >
                            <Box m="3">PRODUCTS</Box>
                          </Link>
                          <a
                            variant="mute"
                            style={{
                              fontWeight: "600",
                              textDecoration: "unset",
                              color: "white",
                            }}
                            href="mailto: vintagebikestore@gmail.com"
                          >
                            <Box m="3" style={{
                              background: "#0062b1",
                              borderRadius: "25px",
                              padding: ".25rem 1rem",
                        }}>vintagebikestore@gmail.com</Box>
                          </a>
                        </Flex>
                      </Reveal>
                    </Box>
                  </Drawer>
                </Box>
              </>
            </Flex>
          </Container>
        </Container>
      </Sticky>
      <Container sx={{ mt: ["14rem", "11rem"] }}>
        <Configure
          attributesToSnippet={["description:10"]}
          snippetEllipsisText="…"
          removeWordsIfNoResults="allOptional"
        />
        <Heading variant="h1" sx={{ fontWeight: "600", textAlign: "center" }}>
          Shop
        </Heading>
        <Flex
          sx={{
            maxWidth: "1285px",
            flexDirection: ["column", "row"],
            position: "relative",
            py: 4,
            px: [3, 4],
          }}
        >
          <IconButton
            variant="filter"
            size="32px"
            sx={styles.filter}
            onClick={() => {
              childRef.current.showAlert()
            }}
          >
            <FaSlidersH />
          </IconButton>
          <Box
            sx={{
              display: ["block", "block"],
              width: ["1", "1", "25%"],
            }}
          >
            <Box onClick={handleToggle}>
              <Panel header="KATEGÓRIÁK"></Panel>
              <HierarchicalMenu
                attributes={[
                  "bREADCRUMBLV1",
                  "bREADCRUMBLV2",
                  "bREADCRUMBLV3",
                  "bREADCRUMBLV4",
                ]}
              />
            </Box>

            {open === true && (
              <Box sx={{ display: ["none", "block"] }} ref={toggle}>
                {TulajData.map((post, index) => (
                  <Box
                    className={post.title}
                    sx={{
                      ".ais-RefinementList::before": {
                        content: `"${
                          post.title.charAt(0).toUpperCase() +
                          post.title.slice(1)
                        }"`,
                        fontSize: "0.875rem",
                        fontWeight: "600",
                      },
                      ".ais-RefinementList.ais-RefinementList--noRefinement": {
                        display: "none",
                      },
                    }}
                  >
                    <RefinementList attribute={post.graph} />
                  </Box>
                ))}
              </Box>
            )}

            <DrawerFilter ref={childRef}>
              <Box ref={toggle}>
                <Panel header="TULAJDONSÁGOK"></Panel>
                {TulajData.map((post, index) => (
                  <Box
                    className={post.title}
                    sx={{
                      ".ais-RefinementList::before": {
                        content: `"${
                          post.title.charAt(0).toUpperCase() +
                          post.title.slice(1)
                        }"`,
                        fontSize: "0.875rem",
                        fontWeight: "600",
                      },
                      ".ais-RefinementList.ais-RefinementList--noRefinement": {
                        display: "none",
                      },
                    }}
                  >
                    <RefinementList attribute={post.graph} />
                  </Box>
                ))}
              </Box>
            </DrawerFilter>
          </Box>
          <Box
            sx={{
              mt: "-1rem",
              width: ["1", "1", "75%"],
            }}
          >
            <Box sx={{ pl: 2 }}>
              <Breadcrumb
                attributes={[
                  "bREADCRUMBLV1",
                  "bREADCRUMBLV2",
                  "bREADCRUMBLV3",
                  "bREADCRUMBLV4",
                ]}
              />
            </Box>
            <CustomHits />
          </Box>
        </Flex>
      </Container>
    </InstantSearch>
  )
}

export default Search

const styles = {
  wrapper: {
    position: `relative`,
    zIndex: 10,
    ".nav-container": {
      bg: `headerBg`,
      position: `fixed`,
      transition: `all 250ms ease-in`,
      overflow: `hidden`,
      py: 4,
      height: ["185px", "unset"],
    },
    ".nav-sticky .nav-container": {
      bg: `white`,
      boxShadow: `0 0 25px rgba(140,152,164,.25)`,
    },
    //Make buttons in header smaller
    ".button-group-button": {
      minWidth: 120,
      fontSize: 1,
      px: 3,
      py: 1,
    },
  },
  search: {
    position: `absolute`,
    zIndex: "9",
    bottom: ["28px", "unset"],
    left: ["24px", "unset"],
    m: "12px",
  },
  filter: {
    position: `absolute`,
    top: ["28px", "12px"],
    display: ["block", "none"],
    bottom: ["40px", "unset"],
    right: ["18px", "12px"],
    cursor: "pointer",
  },
  header: {
    justifyContent: `space-between`,
    alignItems: `center`,
    // height: [`6rem`, `7rem`], //prevent layout shift
  },
  logoContainer: {
    flexShrink: 0,
    mr: [null, null, 3, 5],
    maxWidth: ["80%", "100%"],
  },
  desktopMenu: {
    display: [`none`, null, `block`],
    minWidth: `auto`,
    flexGrow: 1,
  },
  mobileMenu: {
    display: [`block`, null, `none`],
  },
  accordion: {
    ".accordion__button": {
      cursor: `pointer`,
      position: `relative`,
      outline: `none`,
      transition: `all 0.15s ease-in`,
    },
    ".accordion__button:hover": {
      opacity: 0.75,
      "&::before": {
        borderColor: `alpha`,
      },
    },
    ".accordion__button::before": {
      content: `' '`,
      width: `8px`,
      height: `8px`,
      marginRight: `12px`,
      borderBottom: `2px solid currentColor`,
      borderLeft: `2px solid currentColor`,
      position: `absolute`,
      right: 0,
      color: "black",
      top: `30%`,
      transform: `translate(0, -50%) rotate(-45deg)`,
      transition: `all 0.15s ease-in`,
    },
    '.accordion__button[aria-expanded="true"]::before, .accordion__button[aria-selected="true"]::before': {
      transform: `translate(0, -50%) rotate(135deg)`,
      transition: `transform 0.35s ease-in`,
    },
    "[hidden]": {
      display: `none`,
    },
    ".accordion__panel": {
      animation: `fadeIn 0.25s ease-in`,
    },
    "@keyframes fadeIn": {
      "0%": {
        opacity: 0.5,
        transform: "translate(0, -5%)",
      },
      "100%": {
        opacity: 1,
        transform: "translate(0, 0%)",
      },
    },
  },
}
